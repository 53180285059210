import { URLSearchParams } from 'url'
import config from './config'
import { StorageKey } from './lib'
import { StatusData } from '@oneethos/shared'

export class ApiClient {
  private endpoint: string

  constructor() {
    this.endpoint = config.api.baseUrl
  }

  private _initAuthHeaders() {
    const deviceId = localStorage.getItem(StorageKey.DeviceId)
    const headers: Record<string, string> = { 'x-oe-device': deviceId }

    const token = localStorage.getItem(StorageKey.InstallerToken)
    if (token) {
      headers.Authorization = token
    }

    const impersonate = localStorage.getItem(StorageKey.ImpersonateUserId)
    if (impersonate) {
      headers['X-OE-IMPERSONATE'] = impersonate
    }

    return headers
  }

  private _fetch(path: string, opts: RequestInit = {}): Promise<any> {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...this._initAuthHeaders()
    }

    const _opts = {
      ...opts,
      headers: {
        // set default headers but allow override
        ...headers,
        ...opts?.headers
      },
    }

    return new Promise((resolve, reject) => {
      fetch(this.endpoint + path, _opts)
        .then(async (res) => {
          let json
          let text

          try {
            text = await res.text()
            json = JSON.parse(text)
          } catch (ex: any) {
            return reject(ex.message)
          }

          if (res.status >= 200 && res.status < 300) {
            return resolve(json)
          } else {
            json.status = res.status
            json.error = json.error || json.message || JSON.stringify(json)

            // TODO: this hijacks other more user-friendly redirect attempts, reconsider
            if (['UNKNOWN_DEVICE', 'TokenExpiredError', 'LOGIN_REQUIRED'].includes(json.code)) {
              localStorage.removeItem(StorageKey.InstallerToken)
              return window.location.href = `/login?code=${json.code}`
            }

            return reject(json)
          }
        }).catch((ex) => {
          reject(ex)
        })
    })
  }

  public delete(path: string, opts: RequestInit = {}): Promise<any> {
    return this._fetch(path, {
      ...opts,
      method: 'DELETE'
    })
  }

  public get(path: string, opts?: RequestInit): Promise<any> {
    return this._fetch(path, opts)
  }

  private async update(path: string, method: string, body?: object): Promise<any> {
    return this._fetch(path, {
      method,
      body: JSON.stringify(body),
    })
  }

  public post(path: string, body?: object): Promise<any> {
    return this.update(path, 'POST', body)
  }

  public put(url: string, body?: object): Promise<any> {
    return this.update(url, 'PUT', body)
  }

  public patch(url: string, body: object): Promise<any> {
    return this.update(url, 'PATCH', body)
  }

  public authenticatedFetch(path: string, opts: any): Promise<any> {
    const headers = this._initAuthHeaders()
    return new Promise((resolve, reject) => {
      fetch(this.endpoint + path, {
        ...opts,
        headers: { ...opts?.headers, ...headers }
      }).then(async res => {
        if (!res.ok) {
          const text = await res.text()
          const json = JSON.parse(text)
          reject(json)
        } else {
          resolve(res)
        }
      }).catch(reject)
    })
  }

  public getProjects(search: URLSearchParams): Promise<StatusData[]> {
    return this.get(`/loanapps/list?${search.toString()}`).then(list => {
      return list.map(l => {
        const sd = new StatusData(l)
        sd.uiSort()
        return sd
      })
    })
  }

  public getProject(id: string): Promise<StatusData> {
    return this.get(`/loanapps/${id}`).then(item => {
      const p = new StatusData(item)
      p.uiSort()
      return p
    })
  }

  // public delete(url: string): Promise<any> {
  //   return this._fetch(this.endpoint + url, {
  //     method: 'DELETE',
  //     headers: Object.assign({}, HEADERS, {
  //       Auth: this.token
  //     }),
  //   })
  // }
}

export default new ApiClient()