import React, { useState, useEffect, FormEvent } from 'react'
import api from '../api-client'
import { toast } from 'react-toastify'
import { initialTenantProduct, IProduct } from '@oneethos/shared'
import { scrollToTop } from '../lib'
import { MultiSelect, STATE_CODES } from './forms'

interface TenantProductFormProps {
  productID?: string;
  onSave?: (product: IProduct) => void;
  onEdit?: (product: IProduct) => void;
  tenantID: string;
}

const TenantProductForm: React.FC<TenantProductFormProps> = (
  { productID, onSave, tenantID, onEdit }) => {
  const [formData, setFormData] = useState({...initialTenantProduct})

  useEffect(() => {
    if (productID) {
      api.get(`/tenant/${tenantID}/products/${productID}`)
        .then(json => {
          setFormData({ ...json })
        })
        .catch(ex => {
          toast.error(`Error fetching product: ${ex.message}`)
        })
    }
  }, [productID, tenantID])

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    if (!formData.productType) {
      toast.error('Please select a product type')
      return
    }

    const path = `/tenant/${tenantID}/products/${productID || ''}`
    const method = productID ? api.patch(path, formData) : api.post(path, formData)

    method
    .then((response) => {
      if (productID && onEdit) {
        onEdit(response)
      } else if (onSave) {
        onSave(response)
      }
      toast.success(`Product ${productID ? 'updated' : 'saved'}`)
      scrollToTop()
    })
    .catch(error => {
      toast.error(`Error ${productID ? 'updating' : 'saving'} product: ${error.message}`)
    })
  }

  const handleInputChangeText = (e) => {
    const { name, value } = e.target
    setFormData(prevFormData => {
      return {
        ...prevFormData,
        [name]: value
      } 
    })
  }

  const handleInputChangeNumeric = (e) => {
    const { name, value } = e.target
    setFormData(prevFormData => {
      const newValue = value.replace(/[^0-9.]/g, '') 
      return {
        ...prevFormData,
        [name]: newValue
      } 
    })
  }

  const handleSelectState = (states: string[]) => {
    setFormData({ ...formData, approvedStates: states })
  }

  const productFields = [
    { label: 'Term', name: 'term' },
    { label: 'Interest Only Period', name: 'interestOnlyPeriod' },
    { label: 'APR', name: 'apr' },
    { label: 'Autopay APR', name: 'autoPayAPR' },
    { label: 'Interest Rate With Autopay', name: 'interestRateWithAutopay' },
    { label: 'Interest Rate Without Autopay', name: 'interestRateWithoutAutopay' },
    { label: 'Payment Factor With Autopay', name: 'paymentFactorWithAutopay' },
    { label: 'Payment Factor Without Autopay', name: 'paymentFactorWithoutAutopay' },
    { label: 'Dealer Fee', name: 'dealerFee' },
    { label: 'Maximum PPW', name: 'maxPpw' },
    { label: 'Maximum Loan Amount', name: 'maxLoanAmount' },
    { label: 'Minimum Loan Amount', name: 'minLoanAmount' },
  ]

  const productTextAreaFields = [
    { label: 'Disclosures', name: 'disclosures' },
    { label: 'Product Description', name: 'productDescription' },
    { label: 'Detailed Product Description Client Html', name: 'detailedProductDescriptionClientHtml' },
    { label: 'Detailed Product Description Installer Html', name: 'detailedProductDescriptionInstallerHtml' }
  ]

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-6 mb-3">
          <label>Product Type</label>
          <select
            name='productType'
            value={formData.productType}
            onChange={handleInputChangeText}
            className="form-select form-control"
          >
            <option value="">Select a type</option>
            <option value="residential">Residential</option>
            <option value="commercial">Commercial</option>
            <option value="non-profit">Non-Profit</option>
          </select>
        </div>
        {productFields.map((field, index) => (
          <div key={index} className="col-md-6 mb-3">
            <label>{field.label}</label>
            <input
              type='text'
              required
              name={field.name}
              value={formData[field.name as keyof FormData]}
              className="form-control"
              onChange={handleInputChangeNumeric}
            />
          </div>
        ))}
      </div>
      {productTextAreaFields.map((field, index) => (
        <div key={index} className="form-group">
          <label>{field.label}</label>
          <textarea
            name={field.name}
            value={formData[field.name as keyof FormData]}
            className="form-control"
            onChange={handleInputChangeText}
          />
        </div>
      ))}
      <div className='form-group'>
        <label>Approved States{formData.approvedStates && formData.approvedStates.length > 0 && `: ${formData.approvedStates.join(', ')}`}</label>
        <MultiSelect
          options={STATE_CODES}
          values={formData.approvedStates || []}
          onChange={handleSelectState}
          wrapStyle={{ maxHeight: '26em', overflowY: 'auto' }}
        />
      </div>
      <button type="submit" className="btn btn-primary btn-sm">{productID ? 'Update' : 'Save'}</button>
    </form>
  )
}

export default TenantProductForm
