const LoanProductInfo = ({ term, projectType, rate }) => {
  return (
    <>
      <p className="fw-bold text-black">{term} year term</p>
      {projectType === 'residential' ?
        <p className="fw-bold text-black">{rate}% (with Auto Pay)</p> :
        <p className="fw-bold text-black">{rate}%</p>}
      <p className="fw-bold text-black">No Dealer Fee</p>
    </>
  )
}

export default LoanProductInfo