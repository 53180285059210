import { ProjectType } from './loan-application'

export * from './types'
export * from './equifax-credit-report'
export * from './loan-application'
export * from './document-types'
export * from './applicant-race'
export * from './citizenship'
export * from './error'
export * from './org'
export * from './presubmit-status'
export * from './street-address'
export * from './status-data'
export * from './masking'
export * from './individual'
export * from './installer'
export * from './tenant'
export * from './date-utils'
export * from './decision-pro'
export * from './form-types'
export * as Validate from './validators'

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const NAME_REGEX = /^[a-zA-Z0-9.,\-\s\xC0-\uFFFF']+$/  // see test cases, allows diacritics and other
export const PHONE_REGEX = /^[0-9]{10}$/

export const pause = (secs: number) => new Promise(resolve => setTimeout(resolve, secs * 1000))

export const camelize = (str: string) => {
  return str.replace(/[^a-z ]/ig, '').replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
    if (+match === 0) return "" // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase()
  })
}

export const dollars = (num: number | bigint | string, maximumFractionDigits = 2): string => {
  let _num = num
  if (typeof _num === 'string') {
    _num = parseFloat(_num.replace(/[^0-9.]/g, ''))
  }

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits
  }).format(_num)
}

export const dollarsToNum = (s: string): number => {
  return parseFloat(s?.trim().replace(/[^0-9.]/g, ''))
}

export const digit2 = (x: number | string): string => {
  let _x = x
  if (typeof _x === 'string') {
    _x = parseFloat(_x)
  }

  return _x < 10 ? '0' + _x.toString() : _x.toString()
}

export const extractStreetFromAddress = (address: string): string => {
  // not exact, but good enough
  const [numAndStreet] = address.split(',')
  return numAndStreet.replace(/^\d+\s+/, '')
}

// term should be negative months
export const paymentByTerm = (rate: number, term: number, amt?: number): string => {
  if (!amt) { return '$000.00' }
  const out = (amt * (rate * 0.01) / 12) / (1 - (1 + (rate * 0.01) / 12) ** term)
  return dollars(out)
}

export const estimatedClosingCosts = (
  amt: number,
  state: string,
  projectType: ProjectType
): number => {
  if (['commercial', 'non-profit'].includes(projectType)) {
    return amt * 0.015
  } else {
    return 1125 + (state === 'FL' ? (((amt + 1125) / 100) * 0.35) : 0)
  }
}

export const calculateMonthlyPayment = (amount: number, paymentFactor: number) => {
  if (!amount) { return '$000.00' }
  return dollars(amount * paymentFactor)
}
export interface MongooseModel {
  toObject: () => Record<string, unknown>
}
