import React, { useContext, useEffect, useState } from "react"
import { StreetAddress, errorToString } from "@oneethos/shared"
import { FormInput, SubformProps, Validation } from "."
import { AddressInputs } from "../../components/forms"
import { Validate } from '@oneethos/shared'
import api from '../../api-client'
import { GiPartyPopper } from 'react-icons/gi'
import { FaRegHandPaper } from "react-icons/fa"
import { getBrowserFingerprintData } from "../../browser-fingerprint"
import { useAppState, useUtmQuery } from "../../hooks"
import { AppContext } from "../../appContext"
import { requestLoginViaEmail } from "../../components"
import { Spinner } from "@fluentui/react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { lappToFormData } from "./util"

const { validateFields, ValidatorMap } = Validate

export const QualificationCriteria = () => <ul>
  <li>Credit score must be 680 or higher</li>
  <li>Debt to income ratio must be 50% or less</li>
  <li>Discuss alternative financing options with your solar installer</li>
  <li>Consider including a Co-Borrower in your application</li>
</ul>


export const PreQualSuccess = () => {
  return <div className="prequal-success" style={{ textAlign: 'center' }}>
    <GiPartyPopper size={100} color="green" />
    <div style={{ padding: '1em' }}>Hoorary! Based on the information you have provided, you are likely to be approved.</div>
  </div>
}

export const PreQualFailure = () => {
  const { tenant } = useAppState()

  return <div className="prequal-failure">
    <div>
      You might not qualify for a solar loan with {tenant.config.name}.
      Consider the following before applying.
    </div>
    <QualificationCriteria />
  </div>
}

export const PreQualResult = ({ result }) => {
  const { tenant } = useAppState()

  if (result === 'pass') {
    return <PreQualSuccess />
  } else if (result === 'fail') {
    return <PreQualFailure />
  } else if (result === 'no-hit') {
    return <div className="prequal-no-hit">
      <p>
        We couldn't find any information to determine whether you pre-qualify for a solar
        loan with {tenant.config.name}. Please consider the following before applying.
      </p>
      <QualificationCriteria />
    </div>
  }

  return null
}

export const EligibilityFields = ({ formData, update }: Partial<SubformProps>) => {
  return <>
    <FormInput
      field='firstName'
      value={formData.firstName}
      onChange={s => update('firstName', s)}
    />
    <FormInput
      field='lastName'
      value={formData.lastName}
      onChange={s => update('lastName', s)}
    />
    <FormInput
      field='email'
      value={formData.email || ''}
      onChange={s => update('email', s)}
      disabled
    />
    <FormInput
      field='phone'
      value={formData.phone || ''}
      onChange={s => update('phone', s)}
    />
    <div className="form-group">
      <label>Do you live at a different address than where the panels will be installed?</label>
      <select
        className="form-select"
        value={formData.installedAtDifferentLocation}
        onChange={ev => {
          const val = ev.target.value as 'yes' | 'no'

          // initialize the state of installationAddress, or clear it
          update({
            ...formData,
            userAddress: val === 'yes' ? {
              state: 'FL',
              country: 'United States'
            } : formData.installationAddress,
            installedAtDifferentLocation: val
          })
        }}
      >
        <option value='no'>No</option>
        <option value='yes'>Yes</option>
      </select>
    </div>
    {formData.installedAtDifferentLocation === 'yes' ? (
      <AddressInputs
        address={formData.userAddress}
        label="Home Address"
        onChange={address => update('userAddress', address)}
      />
    ) : null}
    <FormInput
      field='statedGrossAnnualIncome'
      value={formData.statedGrossAnnualIncome}
      onChange={s => update('statedGrossAnnualIncome', s)}
    />
  </>
}

const RegistrationRequired = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const query = new URLSearchParams({ route: `/apply/${id}` })
  const url = `/login?${query.toString()}`

  return <div>
    <p>
      To protect your security, we have sent you an email to verify your information. Please
      check your email to continue the application process.
    </p>
    <div className="buttons text-start mb-3">
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => {
          const query = new URLSearchParams({ route: `/apply/${id}` })
          navigate(`/verify-email?${query.toString()}`)
        }}
      >Enter Code</button>
    </div>
    <p>Didn't receive the email or having issues? You may try to <Link to={url}>login or register</Link> to resume your application.</p>
  </div>
}

const PrequalComplete = ({ formData, update, onError, save }) => {
  const { prequalResult } = formData

  const warn = prequalResult !== 'pass'

  return <div>
    <PreQualResult result={prequalResult} />
    <div>
      <div className={['form-group', warn ? 'alert alert-danger' : ''].join(' ')} style={{ cursor: 'pointer' }}>
        {warn ? <div className="mb-3 text-center"><FaRegHandPaper size={50} color="red" /></div> : null}
        <label>
          <input type="checkbox"
            onChange={ev => {
              update('agreesToProceedWithHardCreditInquiry', ev.target.checked ? {
                browser: getBrowserFingerprintData(),
                timestamp: new Date(),
                agrees: true
              } : false)
            }}
            checked={formData.agreesToProceedWithHardCreditInquiry?.agrees || false}
          />
          &nbsp;
          <b>
            I understand that if I decide to proceed with my application, this will
            result in a hard credit inquiry, which can impact my credit score.
          </b>
        </label>
      </div>

      <button type="button"
        className="btn btn-primary"
        disabled={formData.agreesToProceedWithHardCreditInquiry?.agrees !== true}
        onClick={() => save()}>Continue</button>
    </div>
  </div>
}

export const Eligibility = ({ formData, schema, update, save, onError }: SubformProps) => {
  const { state: { registration: { installer } } } = useContext(AppContext)
  const [submitting, setSubmitting] = useState<'identity' | 'prequal' | ''>('')
  const [registrationRequired, setRegistrationRequired] = useState(false)
  const [prequalComplete, setPrequalComplete] = useState(!!formData.prequalResult)
  const [validation, setValidation] = useState<Record<string, string>>({})
  const { t } = useUtmQuery()

  const validate = () => {
    const results = validateFields(schema, formData, [
      'firstName',
      'lastName',
      'phone',
      'email',
      'userAddress',
      'statedGrossAnnualIncome'
    ])

    if (formData.installedAtDifferentLocation === 'yes') {
      const err = ValidatorMap.address(formData.userAddress)
      if (err) {
        results['userAddress'] = 'Installation address must be complete'
      }
    }

    return results
  }

  const submitPrequal = async (testPrequalResult?: 'pass' | 'fail' | 'no-hit') => {
    setSubmitting(installer ? 'prequal' : 'identity')

    try {
      await save({
        data: { ...formData, testPrequalResult },
        advance: false
      })

      if (!installer) {
        await requestLoginViaEmail({
          email: formData.email,
          route: `/apply/${formData._id}`
        })
        setRegistrationRequired(true)
      } else {
        const res = await api.post(`/c/loanapps/${formData._id}/prequal`)
        update(lappToFormData(res))
        setPrequalComplete(true)
      }
    } catch (ex) {
      // it's expected that the user has not completed registration
      if (ex.code !== 'UNREGISTERED') {
        onError(errorToString(ex))
      }
    }

    setSubmitting('')
  }

  useEffect(() => {
    // if the form is valid and the user is logged in, it's likely a user
    // returning from the registration process, attempt to submit the prequal
    const v = validate()
    if (!formData.prequalResult &&
      Object.keys(v).length === 0 &&
      installer && !installer.isSupport) {
      submitPrequal()
    }
  }, [])

  if (submitting === 'prequal') {
    return <div>
      <p>Checking your eligibility...</p>
      <Spinner />
    </div>
  } else if (submitting === 'identity') {
    return <div>
      <p>Verifying security requirements...</p>
      <Spinner />
    </div>
  }

  if (registrationRequired) {
    return <RegistrationRequired />
  }

  if (prequalComplete) {
    return <PrequalComplete
      formData={formData}
      update={update}
      onError={onError}
      save={save}
    />
  }

  return <>
    <h2>Eligibility</h2>
    <p>Get started quickly to know if you're eligible. This will not affect your credit.</p>

    <EligibilityFields formData={formData} update={update} />

    <div className="buttons">
      <button className="btn btn-primary"
        onClick={() => {
          const v = validate()
          setValidation(v)
          if (Object.keys(v).length === 0) {
            submitPrequal()
          }
        }}>
        Check Eligibility
      </button>
      {process.env.REACT_APP_NODE_ENV === 'test' ? <>
        {['pass', 'fail', 'no-hit'].map((test) => <button type="button" key={test}
          className="btn btn-secondary"
          onClick={() => {
            const v = validate()
            setValidation(v)
            if (Object.keys(v).length === 0) {
              submitPrequal(test as 'pass' | 'fail' | 'no-hit')
            }
          }}
        >Check Eligibility ({test})</button>)}
      </> : null}
    </div>
    {validation ? (
      <Validation validation={validation} /> || <div className="alert alert-info">Form is valid</div>
    ) : null}
  </>
}

export default Eligibility