import { dollars, IProduct, paymentByTerm } from "@oneethos/shared"
// import PayoffScenarios from "./payoff-scenarios"
import LoanProductInfo from "./loan-product-info"

export const LoanDetails = ({
  financingTenant,
  config,
  formData,
  showCalculation,
  showIncentive,
  isResidential,
  loanPlusClosingCosts,
  incentive
}) => {
  const { products, rateEffectiveDate } = config
  const findProduct = (type) => products.find((product: IProduct) => product.productType === type)
  const currentProduct = findProduct(formData?.projectType)

  const loanTermYears = (product: IProduct) => (product?.term / 12).toFixed(1)
  const interestRate = (product, withAutopay = true) =>
    withAutopay ? product?.interestRateWithAutopay : product?.interestRateWithoutAutopay

  const calculatePayment = (product, withAutopay = true) => paymentByTerm(
    interestRate(product, withAutopay), -product?.term, loanPlusClosingCosts
  )

  const estimatedPaymentWithAutopay = calculatePayment(currentProduct)
  const estimatedPaymentWithoutAutopay = calculatePayment(currentProduct, false)

  return (
    <>
      <section className="list-item">
        <div className="mt-6 d-flex">
          <div className="form-group">
            <label>Financing Provided By:</label>
            <img
              src={`/api/tenant/logo/${financingTenant._id}`}
              loading="lazy"
              alt="financing provider logo"
              style={{ maxHeight: '50px' }}
            />
          </div>
          {showIncentive ? <div className="form-group">
            <label>Financial Partner:</label>
            <img
              src={incentive.financingPartnerLogo}
              loading="lazy"
              id="logo"
              sizes="(max-width: 479px) 83vw, (max-width: 767px) 72vw, 397.8984375px"
              alt="local financing partner logo"
              style={{ maxHeight: '50px' }}
            />
          </div> : null}
        </div>
        {rateEffectiveDate && (
          <p className="fw-bold text-black">
            Please note that OUR RATES have changed effective {rateEffectiveDate}.
          </p>
        )}
        {currentProduct && (
          <>
            <LoanProductInfo
              projectType={formData?.projectType}
              term={loanTermYears(currentProduct)}
              rate={interestRate(currentProduct, formData?.projectType === 'residential')}
            />
            {formData?.projectType === 'residential' && (
              <p className="text-secondary-emphasis">
                {financingTenant.name} offers a discount to clients that opt into, and maintain,
                automatic monthly payments. The bill amount will automatically be withdrawn
                from the clients checking account.
              </p>
            )}
          </>
        )}
        {showCalculation && (
          <div className="oe-pmt-summary">
            {showIncentive ? <div className="mb-3">
              <div className="text-block-4">Estimated Payment with {incentive?.financingPartner} Incentive: </div>
              <div className="text-block-3 fw-bold">
                {dollars(incentive?.estPriceWithIncentives)}
              </div>
              {incentive?.incentiveDescription
                ? <div className="text-block-4 fw-normal small">{incentive.incentiveDescription}</div>
                : null}
            </div> : null}

            <div className="text-block-4">Estimated Payment{showIncentive ? " before incentive" : null}:</div>
            <div className="text-block-3">
              {isResidential ? estimatedPaymentWithAutopay: estimatedPaymentWithoutAutopay}
            </div>
            {isResidential ? <div>
              <>
                <div className="text-block-4">w/o Auto Pay:</div>
                <div className="text-block-3" style={{ color: 'var(--neutral--700)' }}>
                  {estimatedPaymentWithoutAutopay}
                </div>
              </>
            </div> : null}
            </div>
          )}
      </section>
    </>
  )
}

export default LoanDetails

