import { useState } from 'react'
import './calculator-page.scss'
import { Spinner } from '@fluentui/react'
import api from '../api-client'
import { Section, LoanInformation, LoanDetails } from '../components'
import { useAppState, useUtmQuery } from '../hooks'
import {
  errorToString,
  dollars,
  estimatedClosingCosts,
  EMAIL_REGEX,
  dollarsToNum,
  isServiceableAddress,
  StreetAddress,
  ITenant,
  Incentive
} from '@oneethos/shared'
import { AddressInputs, DollarsInput, NumericInput, TextInput } from '../components/forms'
import RadioToggle from '../components/radio-toggle'
import { FaBuilding, FaHandHoldingHeart, FaHouseChimney } from 'react-icons/fa6'
import { IProduct } from '@oneethos/shared'
import { toast } from 'react-toastify'

type PaymentCalculatorProps = {
  formData?: any
  isResidential: boolean
  onChange?: (formData: any) => void
  showCalculation?: boolean
  onShowCalculation?: () => void
  financingTenant?: ITenant
  incentive?: Incentive
  loadingCalculation?: boolean
  interestRate?: number
  loanTermMonths?: number
}

const validateEmail = (str: string) => {
  return str.length && EMAIL_REGEX.test(str)
}

const calcSystemCost = (formData: any): number => {
  return dollarsToNum(formData.solarCost || '0') +
    dollarsToNum(formData.roofCost || '0') +
    dollarsToNum(formData.storageCost || '0') +
    dollarsToNum(formData.otherCost || '0')
}

const PaymentCalculator = ({
  formData,
  isResidential,
  onChange,
  showCalculation,
  onShowCalculation,
  financingTenant,
  incentive,
  loadingCalculation,
}: PaymentCalculatorProps) => {
  const {
    registration: { installer },
    tenant: { config }
  } = useAppState()

  const [hasTouchedSystemSize, setHasTouchedSystemSize] = useState(false)
  const enableCalculationButton = (
    StreetAddress.isValid(formData.installationAddress) &&
    formData.solarCost &&
    formData.systemSizeKW > 0
  )

  const handleChange = (nextData) => {
    const size = parseFloat(nextData.systemSizeKW) || 0
    const solar = dollarsToNum(nextData.solarCost || '') || 0

    const ppw = size && solar ? dollars(solar / (size * 1000)) : ''
    onChange({
      ...nextData,
      systemCost: calcSystemCost(nextData),
      ppWForPV: ppw
    })
  }

  const { state, country } = (formData.installationAddress || {})
  const { systemCost } = formData
  const fees = estimatedClosingCosts(systemCost, state, formData.projectType)
  const loanPlusClosingCosts = systemCost == 0 ? systemCost : systemCost + fees
  const showIncentive = (
    parseFloat(incentive?.estPriceWithIncentives) > 0 &&
    isResidential
  )

  return <Section>
    <div className="section-title">
      <h2 className="margin-bottom-0">Loan Monthly Payment Calculator</h2>
    </div>
    <div className="form-group">
      <label className="my-2 mb-3">Select Project Type</label>
      <RadioToggle
        value={formData.projectType}
        onChange={v => handleChange({ ...formData, projectType: v })}
        options={[
          { label: 'Residential', value: 'residential', Icon: FaHouseChimney },
          { label: 'Commercial', value: 'commercial', Icon: FaBuilding },
          { label: 'Non-Profit', value: 'non-profit', Icon: FaHandHoldingHeart },
        ]}
      />
    </div>

    {!formData.projectType ? null : <>
      {isResidential ? null : <div className="form-group">
        <label>Business or Non-Profit Name</label>
        <TextInput
          value={formData.businessName}
          onChange={val => handleChange({ ...formData, businessName: val })}
        />
      </div>}

      <AddressInputs
        address={formData.installationAddress || {}}
        label="Address where solar panels will be installed"
        onChange={address => handleChange({
          ...formData,
          installationAddress: address,
        })}
      />

      {
        (
          isResidential &&
          StreetAddress.isValid(formData.installationAddress) &&
          !isServiceableAddress(state, country, installer.org)
        ) ? (
          <div className="alert alert-danger">
            <b>Warning:</b> the consumer will be unlikely to complete the application for this
            address as it is outside the supported area. For more information, please contact
            your account representative.
          </div>
        ) : null
      }
      <h4>System Details</h4>
      <div className="system-details">
        <div className="system-prices">
          <div className="form-group">
            <label>Solar Price</label>
            <DollarsInput
              value={formData.solarCost}
              onChange={v => handleChange({
                ...formData,
                solarCost: v
              })}
            />
          </div>
          <div className="form-group">
            <label>Roof Price</label>
            <DollarsInput
              emptyOk
              value={formData.roofCost}
              onChange={v => handleChange({ ...formData, roofCost: v })}
            />
          </div>
          <div className="form-group">
            <label>Storage Price</label>
            <DollarsInput
              emptyOk
              value={formData.storageCost}
              onChange={v => handleChange({
                ...formData,
                storageCost: v
              })}
            />
          </div>
          {/* <div className="form-group">
            <label>Other</label>
            <DollarsInput
              emptyOk
              value={formData.otherCost}
              onChange={v => handleChange({
                ...formData,
                otherCost: v
              })}
            />
          </div> */}
        </div>
        <div className="system-size">
          <div className="form-group">
            <label>System Size (kW)</label>
            <NumericInput
              value={formData.systemSizeKW}
              onBlur={() => setHasTouchedSystemSize(true)}
              onChange={v => handleChange({
                ...formData,
                systemSizeKW: v
              })}
            />
          </div>
          <div className="form-group">
            <label>Price Per Watt</label>
            <TextInput
              disabled
              value={formData.ppWForPV}
            />
          </div>
        </div>
      </div>
      {hasTouchedSystemSize && formData.ppWForPV && dollarsToNum(formData.ppWForPV) > 3.75 ? (
        <div className="alert alert-danger">
          Loan applications with PPW higher than $3.75 are unlikely to be approved.
        </div>
      ) : null}
      {!isResidential && hasTouchedSystemSize && systemCost < 25_000 ? (
        <div className="alert alert-danger">
          Minimum loan amount for commercial projects is $25,000
        </div>
      ) : null}
      <div className="w-form mb-3 total-system-cost">
        <label htmlFor="loan-amount" className="field-label"
          style={{ fontSize: '1.1em' }}
        ><b>Total System Cost</b></label>
        <input type="text"
          className="form-control"
          maxLength={256}
          name="name"
          disabled
          style={{ fontSize: '1.1em' }}
          data-name="Name"
          placeholder=""
          id="loan-amount"
          value={dollars(systemCost)}
        />
      </div>
      {financingTenant ?
        <LoanDetails
          financingTenant={financingTenant}
          config={config}
          formData={formData}
          isResidential={isResidential}
          showCalculation={showCalculation}
          showIncentive={showIncentive}
          loanPlusClosingCosts={loanPlusClosingCosts}
          incentive={incentive}
        /> : <div style={{ textAlign: 'center' }}>
          {loadingCalculation ? <Spinner /> : <>
            {enableCalculationButton ? null : <div className="alert alert-info">
              Please provide Address, System Size, and System Cost to calculate payment
            </div>}
            <button
              type="button"
              className="btn btn-primary"
              disabled={!enableCalculationButton}
              onClick={() => onShowCalculation()}
            >Calculate Payment</button>
          </>}
        </div>
      }
    </>}
  </Section >
}

const SendApplicationLink = ({ formData, isResidential }: PaymentCalculatorProps) => {
  const [email, setEmail] = useState('')
  const [sending, setSending] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [referralId, setReferralId] = useState('')

  const { tenant: { config } } = useAppState()

  const { products } = config

  const residential = products.find((product: IProduct) => product.productType === 'residential')
  const commercial = products.find((product: IProduct) => product.productType === 'commercial')
  const nonProfit = products.find((product: IProduct) => product.productType === 'non-profit')

  let interest: number
  let loanTerm: number

  if (formData?.projectType === 'residential') {
    interest = residential?.interestRateWithAutopay
    loanTerm = residential?.term
  } else if (formData?.projectType === 'commercial') {
    interest = commercial?.interestRateWithoutAutopay
    loanTerm = commercial?.term
  } else if (formData?.projectType === 'non-profit') {
    interest = nonProfit?.interestRateWithoutAutopay
    loanTerm = nonProfit?.term
  }

  const utm = useUtmQuery()
  const { systemCost } = formData

  const submitReferral = () => {
    setError('')
    setSuccess('')

    if (!validateEmail(email)) {
      setError('Please enter a valid email address')
      return
    } else if (!systemCost) {
      setError('Please enter a valid loan amount')
      return
    }

    setSending(true)
    api.post('/loanapps', {
      ...utm,
      email,
      projectType: formData.projectType,
      businessName: formData.businessName || '',
      interest,
      loanDurationMonths: loanTerm,
      systemCost: formData.systemCost,
      installationAddress: formData.installationAddress,
      userAddress: formData.installationAddress,
      solarCost: dollarsToNum(formData.solarCost),
      roofCost: dollarsToNum(formData.roofCost || '$0'),
      storageCost: dollarsToNum(formData.storageCost || '$0'),
      systemSizeKW: parseFloat(formData.systemSizeKW),
      ppWForPV: dollarsToNum(formData.ppWForPV)
    }).then(res => {
      if (res.message) {
        setSuccess(res.message)
      } else {
        setSuccess("Thank you for submitting your request. An email confirmation will be sent to your client shortly.")
      }

      if (res._id) {
        setReferralId(res._id)
      }
    }).catch(ex => {
      setSuccess('')
      setError(errorToString(ex))
    }).finally(() => setSending(false))
  }

  if (success) {
    return <div>
      <div className="alert alert-success">{success}</div>

      {referralId ? <div>
        <h3>Has the consumer already signed a contract?</h3>
        <p>Visit the project page to upload the signed contract and avoid delays</p>
        <div className="buttons">
          <a className="btn btn-primary" href={`/project/${referralId}?v=checklist`}>View Project Page</a>
        </div>
      </div> : null}
    </div>
  }


  return <div>
    <h2 className="margin-bottom-medium">Send Application Link </h2>
    <div className="w-form">
      <form id="email-form-2" name="email-form-2" data-name="Email Form 2" method="get">
        <div className="form-group">
          <label>System Cost: </label>
          <div id="send-amount" className="text-large fw-semibold">
            {systemCost ? dollars(systemCost) : '$0.00'}
          </div>
        </div>
        {systemCost >= 125_000 && isResidential ? <div className="alert alert-info">
          Loans larger than $125,000 will require additional income verification
        </div> : null}
        <div className="form-group">
          <label>Enter client email address</label>
          <input type="email"
            className="form-control"
            maxLength={256}
            name="email"
            data-name="email"
            placeholder="client@email.com"
            id="email"
            autoComplete="off"
            value={email}
            onChange={ev => setEmail(ev.target.value)}
          />
        </div>

        <div className="buttons form-group">
          {sending ? <Spinner /> : <button
            type="button"
            className="btn btn-primary"
            onClick={submitReferral}
          >Send Referral Link</button>}
        </div>
        {error ? <div className="alert alert-danger">{error}</div> : null}
      </form>
    </div>
  </div >
}

export const CalculatorPage = () => {
  const [formData, setFormData] = useState<any>({ systemCost: 0 })
  const [showCalculation, setShowCalculation] = useState<boolean>(false)
  const [financingTenant, setFinancingTenant] = useState(null)
  const [incentive, setIncentive] = useState()
  const [loadingCalculation, setLoadingCalculation] = useState(false)
  const { tenant: { config } } = useAppState()

  const product = config.products.find((product: IProduct) =>
    product.productType === formData.projectType)

  const interestRate = product?.interestRateWithAutopay
  const loanTermMonths = product?.term

  const isResidential = !['commercial', 'non-profit'].includes(formData.projectType)

  return <div className="calculator-page">
    <PaymentCalculator
      formData={formData}
      financingTenant={financingTenant}
      isResidential={isResidential}
      onChange={data => {
        // change in address or system price requires recalculation
        setFinancingTenant(null)
        setShowCalculation(false)
        setIncentive(undefined)
        setFormData(data)
      }}
      showCalculation={showCalculation}
      incentive={incentive}
      loadingCalculation={loadingCalculation}
      onShowCalculation={() => {
        const query = new URLSearchParams({
          ...formData.installationAddress,
          projectType: formData.projectType,
          systemCost: formData.systemCost,
          interestRate,
          loanTermMonths
        })

        setLoadingCalculation(true)
        api.get(`/tenant/for-project?${query.toString()}`).then(res => {
          if (res.tenant) {
            setFinancingTenant(res.tenant)
          }

          setIncentive(res.incentive)
          setShowCalculation(true)
        }).catch(ex => toast.error(errorToString(ex)))
          .finally(() => setLoadingCalculation(false))
      }}
    />
    {showCalculation ? <>
      <LoanInformation isResidential={isResidential} />
      <Section>
        <SendApplicationLink formData={formData} isResidential={isResidential} />
      </Section>
    </> : null}
  </div>
}

export default CalculatorPage
